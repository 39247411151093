<template>
	<div id="base">
		<div class="w-100">
			<h1 class="title-search">{{$t("titles.schedule")}}</h1>
			<div id="toolbar-wrapper">
				<div id="toolbar">
					<div class="v-mobile d-flex">
						<WorkersDropdown :show-salon="true" ref="workersDropdown"/>
						<div class="block-date d-flex">
							<div id="left-arrow-cont" v-on:click="goPrev">
								<svg id="left-arrow" version="1.1" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:space="preserve" xmlns:xlink="http://www.w3.org/1999/xlink"><polygon points="51.73,7.46 44.26,0 12.26,32 44.26,64 51.73,56.53 27.2,32"></polygon></svg>
							</div>
							<span id="current-month">{{ month.format( 'MMMM' ) }}<br>{{ month.format( 'YYYY' ) }}</span>
							<div id="right-arrow-cont" v-on:click="goNext">
								<svg id="right-arrow" version="1.1" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:space="preserve" xmlns:xlink="http://www.w3.org/1999/xlink"><polygon points="19.73,0 12.26,7.46 36.8,32 12.26,56.53 19.73,64 51.73,32"></polygon></svg>
							</div>
						</div>
					</div>
					<!--div id="workers">
						<div
							v-if="!$store.getters.salonHasUniqueAccount()"
							class="workers-place" :style="{ 'flex-basis': 'calc(' + workerWidth + '% - 1rem )' }">
							<div class="color-legend" :style="{ background: $store.getters.getGeneralWorker().color, border: '1px solid ' + Color($store.getters.getGeneralWorker().color).darken(0.25).toString() }"></div>
							<div class="name-legend">{{ $t('common.salon') }}</div>
						</div>
						<div
							class="workers-place"
							v-for="worker in $store.state.workers.filter(w => !w.hidden)"
							:key="worker.id"
							:style="{ 'flex-basis': 'calc(' + workerWidth + '% - 1rem )' }"
						>
							<div class="color-legend" :style="'background: ' + worker.color"></div>
							<div class="name-legend">{{ worker.fname }}</div>
						</div>
					</div-->
				</div>
			</div>
		</div>
		<div id="calendar-container">
			<div id="calendar-wrapper" :style="{ transition: 'transform 0.35s ease-out 0s', transform: 'none' }">
				<div class="calendar-wrapper">
					<table class="calendar table-sticky">
						<thead>
							<tr>
								<th v-for="day in $t('time.shortweekdays')">{{ day }}</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="j in 6" :key="j">
								<td
									v-for="i in 7"
									:key="i"
									:class="{ 'calendar-closed': $store.state.schedules[i%7].morningop == '0' && $store.state.schedules[i%7].afternop == '0', today: today( i, j ) }"
									:style="{ height: 2.5 + Math.max( 1.5, caseHeight ) + 'rem' }"
								>
									<div class="calendar-title">
										<span class="calendar-title-text">{{ dayjs(date).add( (j - 1) * 7 + i - 1, "days").format("D") }}</span>
										<template
											v-for="( absence, idx ) in schedules[(j - 1) * 7 + i - 1]"
											v-if="absence && absence.status !== 'refused' && ( $store.state.worker.admin || absence.worker.id == $store.state.worker.id || absence.status == 'validated' ) && ( $refs.workersDropdown.workerid < 0 || absence.worker.id == $refs.workersDropdown.workerid )"
										>
											<div
												:key="absence.id"
												:data-event="absence.id"
												:class="'calendar-event ' + ( ( (j - 1) * 7 + i - 1 ) == absence.startIndex ? 'start ' : '' ) + ( ( (j - 1) * 7 + i - 1 ) == absence.endIndex ? 'end ' : '' ) + ( absence.status || '' )"
												:style="{ top: 2 + idx * 1.5 + 'rem', background: ( absence.status != 'validated' ? absence.pendingColor : absence.worker.color ), border: ( absence.worker.id == $store.getters.getGeneralWorker().id ? '1px solid ' + Color(absence.worker.color).darken(0.25).toString() : '' ) }"
											>
												<!--div v-if="i == 1 || ( (j - 1) * 7 + i - 1 ) == absence.startIndex">{{ absence.worker.fname + " " + ( absence.worker.lname || "" ).substr( 0, 1 ).toUpperCase() }}</div-->
											</div>
										</template>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<Popper
					class="calendar-event-dialog"
					ref="eventPopper"
					:visible-arrow="false"
					:options="{ placement: 'bottom' }"
					trigger="clickToOpen"
				>
					<div class="card">
						<div v-if="Object.keys(shownEvent).length > 0" class="card-body">
							<template v-if="$store.state.domain == 'esthetics'">
								<h5 v-if="shownEvent.worker.id == $store.getters.getGeneralWorker().id" class="card-title">{{ $tc('administration.salonclose',1) }}</h5>
								<h5 v-else class="card-title">{{$t("schedules.holidaystat")}}<i>{{ $t( `data.absences['${shownEvent.status || ''}']` ) }}</i></h5>
							</template>
							<template v-else-if="shownEvent.status == 'canceled'">
								<h5 class="card-title">{{ $t('common.canceled') }}</h5>
							</template>
							<i18n tag="h6" class="card-subtitle mb-2 text muted" path="time.range">
								<template v-slot:start>{{ $d( shownEvent.startDate, ( shownEvent.startDate.getHours() == 0 && shownEvent.startDate.getMinutes() == 0 ) ? "numericonlydate" : "short" ) }}</template>
								<template v-slot:end>{{ $d( shownEvent.endDate, ( shownEvent.endDate.getHours() == 0 && shownEvent.endDate.getMinutes() == 0 ) ? "numericonlydate" : "short" ) }}</template>
							</i18n>
							<p class="card-text">{{ shownEvent.message }}</p>
							<div v-if="$store.state.domain == 'coachs' && ( !shownEvent.status || shownEvent.status == 'validated' )" class="admin-buttons">
								<StatusButton id="cancel" type="outline-danger" alert="modal" @click="showConfirm = { $event, go: () => setStatus(shownEvent, 'canceled', $event), msg: 'schedules.confirmcancel' }">{{ $t('common.cancel') }}</StatusButton>
							</div>
							<div class="admin-buttons" v-if="$store.state.worker.admin && shownEvent.status === null">
								<StatusButton id="validate" type="outline-success" alert="modal" @click="showConfirm = { $event, go: () => setStatus(shownEvent, 'validated', $event), msg: 'schedules.confirmaccept' }"><i class="fas fa-check"></i></StatusButton>
								<StatusButton id="forbidden" type="outline-danger" alert="modal" @click="showConfirm = { $event, go: () => setStatus(shownEvent, 'refused', $event), msg: 'schedules.confirmdeny' }"><i class="fas fa-times"></i></StatusButton>
							</div>
							<!--router-link v-if="$store.state.worker.admin" class="card-link" :to="'/administration/vacations?id=' + shownEvent.id">{{$t("schedules.seeon")}}</router-link-->
							<!--router-link v-else-if="shownEvent.worker.id === $store.state.worker.id" class="card-link" :to="''">{{$t("schedules.seeon")}}</router-link-->
						</div>
					</div>

					<button
						id="event-toggler"
						ref="eventToggler"
						slot="reference"
						class="btn btn-secondary dropdown-toggle"
					></button>
				</Popper>
			</div>
		</div>
		<div v-if="$store.state.worker.username != 'general'" id="new-card" class="card col-md-12">
			<h2 class="title-request card-header">{{ $store.state.worker.admin ? ( $refs.requestMode && $refs.requestMode.checked ? $tc("administration.salonclose", 1) : $t("common.requestadm") ) : $t("common.request") }}</h2>
			<div v-if="!$store.getters.salonHasUniqueAccount() && $store.state.worker.admin" class="request-switch">
				<span @click="$refs.requestMode.setChecked(false)">{{ $t('common.account') }}</span>
				<ToggleSwitch ref="requestMode" :color="themes[$store.state.workers.find(w => w.username == 'general').theme]" @change="salonPass = $event"></ToggleSwitch>
				<span @click="$refs.requestMode.setChecked(true)">{{ $t('common.salon') }}</span>
			</div>
			<form id="request" class="form" @submit.prevent>
				<div class="input-group form-group">
					<div class="request-calendar">
						<div class="form-group">
							<div class="checkbox checkbox-primary">
								<input type="checkbox" class="d-none" id="request-calendar-1-time" v-model="preciseTimeForStart" />
								<label for="request-calendar-1-time">
									<div class="checkbox-text">{{$t("schedules.specify")}}</div>
								</label>
							</div>
							<div id="request-calendar-1" class="input-group date" data-target-input="nearest">
								<input ref="requestStart" class="form-control form-control datetimepicker-input" data-target="#request-calendar-1" name="start" onkeydown="return false;" :placeholder="$t('common.choosesdate')" type="text"/>
								<span class="input-group-append" data-target="#request-calendar-1" data-toggle="datetimepicker">
									<div class="input-group-text">
										<i class="fa fa-calendar"></i>
									</div>
								</span>
							</div>
						</div>
						<div class="form-group">
							<div class="checkbox checkbox-primary">
								<input type="checkbox" class="d-none" id="request-calendar-2-time" v-model="preciseTimeForEnd" />
								<label for="request-calendar-2-time">
									<div class="checkbox-text">{{$t("schedules.specify")}}</div>
								</label>
							</div>
							<div id="request-calendar-2" class="input-group date" data-target-input="nearest">
								<input ref="requestEnd" class="form-control form-control datetimepicker-input" data-target="#request-calendar-2" name="end" onkeydown="return false;" :placeholder="$t('common.chooseedate')" type="text"/>
								<span class="input-group-append" data-target="#request-calendar-2" data-toggle="datetimepicker">
									<div class="input-group-text">
										<i class="fa fa-calendar"></i>
									</div>
								</span>
							</div>
						</div>
						<div class="duration-hint">
							{{ holidayDuration }}
						</div>
					</div>
				</div>
				<div class="temp">{{$t("common.reasons")}}</div>
				<div class="input-group form-group message">
					<textarea id="request-reasons" maxlength="50" ref="requestMessage" class="form-control " name="message" :placeholder="$t('common.reasonop')" type="text"/>
				</div>
				<div class="input-group form-group d-flex opacity-trans" :class="{ show: requestStartChanged && requestEndChanged }">
					<StatusButton id="send" type="primary request-send" :enabled="requestStartChanged && requestEndChanged" :hidden="!showMakeRequest" @click="makeRequest">
						<i v-if="$store.state.worker.admin" class="fas fa-check"></i>
						<i v-else class="fas fa-paper-plane"></i>
					</StatusButton>
				</div>
			</form>
		</div>
		<Confirm v-if="showConfirm" @confirm="showConfirm.go(); showConfirm = false" @close="showConfirm.$event( -1 ); showConfirm = false" ref="statusConfirm">
			{{ $t(showConfirm.msg) }}
		</Confirm>
	</div>
</template>

<script>
	import Popper from "vue-popperjs"
	import dayjs from "dayjs"
	import Color from "color"
	import WorkersDropdown from "../components/WorkersDropdown.vue"
	import StatusButton from "../components/StatusButton.vue"
	import Confirm from "../modals/Confirm.vue"
	import ToggleSwitch from "../components/ToggleSwitch.vue"
	import constants from "../constants.js"

	export default {
		components: {
			Confirm,
			Popper,
			StatusButton,
			WorkersDropdown,
			ToggleSwitch
		},
		data() {
			return {
				dayjs,
				Color,
				themes: constants.themes,
				caseHeight: 0,
				absences: [],
				absencesData: [],
				schedules: [],
				month: dayjs().set("date", 1).set("hours", 0).set("minutes", 0).set("seconds", 0).set("milliseconds", 0),
				date: dayjs().set(),
				preciseTimeForStart: false,
				preciseTimeForEnd: false,
				requestStart: null,
				requestEnd: null,
				requestStartChanged: false,
				requestEndChanged: false,
				showMakeRequest: false,
				showConfirm: false,
				shownEvent: {},
				holidayDuration: ''
			}
		},
		computed: {
			workerWidth() {
				return Math.max( 25, 100 / this.$store.state.workers.reduce( ( a, b ) => a + ( b.hidden ? 0 : 1 ), 0 ) );
			},
		},
		methods: {
			today( i, j ) {
				let date = this.date.add( (j - 1) * 7 + i - 1, "days").add( 1, "minute" )
				let tod = dayjs().hour(0).minute(0)
				return date.isAfter(tod) && date.isBefore( tod.add(1,'day') )
			},
			goPrev() {
				this.month = this.month.subtract( 1, "month" );
				this.update();
			},
			goNext() {
				this.month = this.month.add( 1, "month" );
				this.update();
			},
			setStatus( absence, status, callback ) {
				this.$api.absence.patch( absence.id, { status: status } ).then( response => {
					callback( true )
				}).catch( error => {
					callback( false, error.response.data.error )
					console.log( error.response.data.error )
				})
				callback( true )
			},
			calculateAbsences() {
				let absences = this.absencesData
				let matrix = []
				let biggestDay = 0

				for ( let i = 0; i < absences.length; i++ ) {
					if ( absences[i].status !== "refused" ) {
						if ( !isNaN(parseInt(absences[i].worker)) ) {
							absences[i].worker = this.$store.getters.getWorker( absences[i].worker )
						}
						absences[i].pendingColor = Color( absences[i].worker.color ).alpha( 0.5 ).toString()
						absences[i].startDate = new Date( absences[i].start )
						absences[i].endDate = new Date( absences[i].end )
						absences[i].startDay = dayjs( absences[i].start ).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
						absences[i].endDay = dayjs( absences[i].end )
						if ( absences[i].endDay.hour() == 0 && absences[i].endDay.minute() == 0 && absences[i].endDay.second() == 0 ) {
							absences[i].endDay = absences[i].endDay.add( -1, "days" )
							absences[i].endDay = absences[i].endDay.set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })
							absences[i].endDate = absences[i].endDate.addDays( -1 )
						}
						let position = absences[i].startDay.diff( this.date, "days" )
						let duration = absences[i].endDay.diff( absences[i].startDay, "days" ) + 1
						let verticalPosition = 0
						if ( matrix[position] !== undefined ) {
							for ( ; verticalPosition < matrix[position].length; verticalPosition++ ) {
								if ( matrix[position][verticalPosition] === undefined ) {
									break
								}
							}
						}
						for ( let j = 0; j < duration; j++ ) {
							matrix[position + j] = matrix[position + j] || [];
							matrix[position + j][verticalPosition] = absences[i];
							biggestDay = Math.max( biggestDay, matrix[position + j].length );
						}
						absences[i].startIndex = position;
						absences[i].endIndex = position + duration - 1;
					}
				}

				this.caseHeight = biggestDay * 1.5;
				this.absences = absences;
				this.schedules = matrix;
			},
			update() {
				this.date = this.month.set( "date", 1 );
				while ( this.date.day() != 1 ) {
					console.log( this.date.date(), this.date.day() );
					this.date = this.date.subtract( 1, "day" );
				}
				this.$api.absences.get({ "end[gte]": this.date.format("YYYY-MM-DD"), "start[lt]": dayjs(this.date).add( 6 * 7, "days" ).format("YYYY-MM-DD"), "{sort}": "asc:start" }).then(response => {
					this.absencesData = response.data;
					this.calculateAbsences();
				});
			},
			makeRequest( callback ) {
				/*
				let start = dayjs(this.requestStart).toISOString()
				let end = dayjs(this.requestEnd).toISOString()
				if ( !this.preciseTimeForStart ) {
					start = dayjs(this.requestStart).hour(0).minute(0).second(0).millisecond(0).toISOString()
				}
				if ( !this.preciseTimeForEnd ) {
					end = dayjs(this.requestEnd).add(1, "day").hour(0).minute(0).second(0).millisecond(0).toISOString()
				}
				*/
				let start = dayjs(this.requestStart).format( "YYYY-MM-DD HH:mm" )
				let end = dayjs(this.requestEnd).format( "YYYY-MM-DD HH:mm" )
				if ( !this.preciseTimeForStart ) {
					start = dayjs(this.requestStart).hour(0).minute(0).second(0).millisecond(0).format( "YYYY-MM-DD HH:mm" )
				}
				if ( !this.preciseTimeForEnd ) {
					end = dayjs(this.requestEnd).add(1, "day").hour(0).minute(0).second(0).millisecond(0).format( "YYYY-MM-DD HH:mm" )
				}
				let req = {
					start: start, // this.$refs.requestStart.value,
					end: end, // this.$refs.requestEnd.value,
					message: this.$refs.requestMessage.value,
					worker: this.$refs.requestMode && this.$refs.requestMode.checked ? this.$store.getters.getGeneralWorker().id : this.$store.state.worker.id
				}
				if ( !this.preciseTimeForEnd ) {
				//	req.end = dayjs( req.end ).hour( 23 ).minute( 59 ).second( 59 ).millisecond( 999 ).toISOString( "YYYY-MM-DD HH:mm" )
				}
				this.$api.absences.post( req ).then( response => {
					callback( true )
					this.update()
					this.preciseTimeForStart = false
					this.preciseTimeForEnd = false
					this.requestStartChanged = false
					this.requestEndChanged = false
					try {
						this.$refs.requestMessage.value = ""
						$(this.$el).find('#request-calendar-1').datetimepicker( "clear" )
						$(this.$el).find('#request-calendar-2').datetimepicker( "clear" )
					} catch ( e ) {
						// tempusdominus throws a false-error...
					}
					setTimeout( () => {
						this.showMakeRequest = false
					}, 500 )
				}).catch( error => {
					console.log( error )
					callback( false, error.response ? error.response.data.error : error.message )
				});
			},
			getHolidayDuration() {
				if (!this.$refs.requestEnd.value || !this.$refs.requestStart.value)
					return ''
				let start = dayjs(this.$refs.requestEnd.value)
				if (!this.preciseTimeForEnd)
					start = start.add(1, 'day')
				return dayjs.duration(
					start.diff(dayjs(this.$refs.requestStart.value))
				).locale(this.$i18n.locale).humanize()
			}
		},
		updated() {
			$(this.$el).find( ".calendar-event" ).hover( event => {
				let id = event.target.getAttribute( "data-event" );
				$(this.$el).find( "[data-event=" + id + "]" ).addClass( "hover" );
			}, event => {
				let id = event.target.getAttribute( "data-event" );
				$(this.$el).find( "[data-event=" + id + "]" ).removeClass( "hover" );
			});

			$(this.$el).find( ".calendar-event" ).click( event => {
				let id = event.target.getAttribute( "data-event" );
				this.$refs.eventToggler.style.left = event.pageX - 1 + "px";
				this.$refs.eventToggler.style.top = event.pageY - 1 + "px";
				this.shownEvent = this.absences.find( elem => elem.id === parseInt(id) );
				this.$nextTick( () => {
					setTimeout( () => {
						this.$refs.eventToggler.click()
					}, 100 )
				})
			})

			$(this.$el).find('#request-calendar-1').datetimepicker({
				"format": "YYYY/MM/DD",
				"widgetPositioning": { "vertical": "bottom", "horizontal": "right" },
				"debug": ( process.env.VUE_APP_MODE === "development" ),
				"icons": { "time": "fa fa-clock" },
				"locale": "fr",
				"useCurrent": false,
				"minDate": dayjs(),
			})
			$(this.$el).find('#request-calendar-1').on( "show.datetimepicker", () => {
				$(this.$el).find('#request-calendar-1').datetimepicker( "minDate", ( new Date() ).addDays( 1 ) )
			})
			$(this.$el).find('#request-calendar-1').on( "change.datetimepicker", (e) => {
				this.requestStart = e.date
				this.requestStartChanged = true
				this.holidayDuration = this.getHolidayDuration()
				this.showMakeRequest = true
				if ( !this.$refs.requestEnd.value ) {
					$(this.$el).find('#request-calendar-2').datetimepicker( "date", e.date )
				}
			})

			$(this.$el).find('#request-calendar-2').datetimepicker({
				"format": "YYYY/MM/DD",
				"widgetPositioning": { "vertical": "bottom", "horizontal": "right" },
				"debug": ( process.env.VUE_APP_MODE === "development" ),
				"icons": { "time": "fa fa-clock" },
				"locale": "fr",
				"useCurrent": false,
				"minDate": dayjs(),
			})
			$(this.$el).find('#request-calendar-2').on( "show.datetimepicker", () => {
				$(this.$el).find('#request-calendar-2').datetimepicker( "minDate", ( new Date() ).addDays( 1 ) )
			})
			$(this.$el).find('#request-calendar-2').on( "change.datetimepicker", (e) => {
				this.requestEnd = e.date
				this.requestEndChanged = true
				this.holidayDuration = this.getHolidayDuration()
				this.showMakeRequest = true
				if ( !this.$refs.requestStart.value ) {
					$(this.$el).find('#request-calendar-1').datetimepicker( "date", e.date )
				}
			})
		},
		watch: {
			preciseTimeForStart() {
				$(this.$el).find('#request-calendar-1').datetimepicker( "format", ( this.preciseTimeForStart ? "YYYY/MM/DD HH:mm" : "YYYY/MM/DD" ) );
				this.holidayDuration = this.getHolidayDuration()
			},
			preciseTimeForEnd() {
				$(this.$el).find('#request-calendar-2').datetimepicker( "format", ( this.preciseTimeForEnd ? "YYYY/MM/DD HH:mm" : "YYYY/MM/DD" ) );
				this.holidayDuration = this.getHolidayDuration()
			}
		},
		mounted() {
			this.update();
			this.$api.absences.watch( event => {
				console.log( event )
				if ( event.method === "DELETE" ) {
					let changed = false
					for ( let i = 0; i < event.data.length; i++ ) {
						let found = this.absencesData.findIndex( abs => abs.id == event.data[i].id )
						if ( found >= 0 ) {
							this.absencesData.splice( found, 1 )
							changed = true
						}
					}
					if ( changed ) {
						this.calculateAbsences()
					}
				} else if ( event.method === "PATCH" ) {
					let changed = false
					for ( let i = 0; i < event.data.length; i++ ) {
						let found = this.absencesData.find( abs => abs.id == event.data[i].id )
						if ( found ) {
							Object.assign( found, event.data[i] )
							found.start = new Date( found.start )
							found.end = new Date( found.end )
							changed = true
						}
					}
					if ( changed ) {
						this.calculateAbsences()
					}
				} else if ( event.method === "POST" ) {
					for ( let i = 0; i < event.data.length; i++ ) {
						event.data[i].start = new Date( event.data[i].start )
						event.data[i].end = new Date( event.data[i].end )
						for ( let j = 0; j < this.absences.length; j++ ) {
							console.log( "compare", this.date, event.data[i].start, event.data[i].end )
							if ( ( dayjs(event.data[i].end).isSame( this.date ) || dayjs(event.data[i].end).isAfter( this.date ) ) && dayjs(event.data[i].start).isBefore( dayjs(this.date).add( 6 * 7, "days" ) ) ) {
								console.log( "push" )
								this.absencesData.push( event.data[i] )
								this.calculateAbsences()
								break
							}
						}
					}
				}
			})
		}
	}
</script>


<style scoped lang="scss" src="../css/pages/schedules.scss"/>

