import { render, staticRenderFns } from "./Schedules.vue?vue&type=template&id=52a95cf6&scoped=true"
import script from "./Schedules.vue?vue&type=script&lang=js"
export * from "./Schedules.vue?vue&type=script&lang=js"
import style0 from "../css/pages/schedules.scss?vue&type=style&index=0&id=52a95cf6&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52a95cf6",
  null
  
)

export default component.exports